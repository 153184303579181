import React from 'react';
import { Code2, Palette, Database, Globe, Server, Terminal } from 'lucide-react';

const skills = [
  {
    name: "Frontend Development",
    icon: <Code2 className="h-6 w-6 text-gray-900 dark:text-white" />,
    technologies: ["React", "Next.js", "Tailwind CSS"],
  },
  {
    name: "UI/UX Design",
    icon: <Palette className="h-6 w-6 text-gray-900 dark:text-white" />,
    technologies: ["Figma", "Adobe XD", "Sketch"],
  },
  {
    name: "Backend Development",
    icon: <Server className="h-6 w-6 text-gray-900 dark:text-white" />,
    technologies: ["Node.js", "Express", "Python"],
  },
  {
    name: "Database Management",
    icon: <Database className="h-6 w-6 text-gray-900 dark:text-white" />,
    technologies: ["MongoDB", "PostgreSQL", "MySQL"],
  },
  {
    name: "DevOps",
    icon: <Terminal className="h-6 w-6 text-gray-900 dark:text-white" />,
    technologies: ["Docker", "Kubernetes", "CI/CD"],
  },
  {
    name: "Web Technologies",
    icon: <Globe className="h-6 w-6 text-gray-900 dark:text-white" />,
    technologies: ["HTML5", "CSS3", "JavaScript"],
  },
];

export function Skills() {
  return (
    <section id="skills" className="py-12 bg-gray-50 dark:bg-gray-900 transition-colors duration-300">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8 text-gray-900 dark:text-white">My Skills</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {skills.map((skill, index) => (
            <div
              key={index}
              className="bg-white dark:bg-gray-800 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 flex flex-col justify-between h-full"
            >
              <div className="p-6 flex-grow">
                <div className="flex items-center mb-4">
                  <div className="p-2 bg-gray-100 dark:bg-gray-700 rounded-full">
                    {skill.icon}
                  </div>
                </div>
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
                  {skill.name}
                </h3>
                <div className="flex flex-wrap gap-2">
                  {skill.technologies.map((tech, techIndex) => (
                    <span
                      key={techIndex}
                      className="text-sm bg-gray-200 text-gray-800 px-3 py-1 rounded-full dark:bg-gray-700 dark:text-gray-200"
                    >
                      {tech}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
