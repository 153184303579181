import React from 'react';

export function Footer() {
  return (
    <footer className="bg-gray-100 dark:bg-gray-800 py-6 text-gray-700 dark:text-gray-300">
      <div className="container mx-auto px-4 text-center">
        <p>© {new Date().getFullYear()} Rachel Zhu. All rights reserved.</p>
      </div>
    </footer>
  );
}
