import React from 'react';

const Blog = () => {
  return (
    <h1>
        Welcome to My Blog
    </h1>
  );
};

export default Blog;
