import React, { useState, useEffect } from 'react';
import { Moon, Sun } from 'lucide-react';

export function Navbar({ darkMode, toggleDarkMode }) {
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setIsVisible(currentScrollY < lastScrollY || currentScrollY < 50);
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  return (
    <nav
      className={`fixed top-0 left-0 right-0 shadow-md z-10 transition-transform duration-300 ${
        isVisible ? 'translate-y-0' : '-translate-y-full'
      } ${
        darkMode
          ? 'bg-primary-dark text-white'
          : 'bg-primary text-gray-900'
      }`}
    >
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          {/* Logo */}
          <a href="/" className="text-xl font-bold">
            Rachel Zhu
          </a>
          {/* Navigation Links */}
          <div className="flex items-center space-x-6">
            <a
              href="/"
              className="hover:text-gray-100 transition-colors"
            >
              About Me
            </a>
            <a
              href="/projects"
              className="hover:text-gray-100 transition-colors"
            >
              Projects
            </a>
            <a
              href="/contact"
              className="hover:text-gray-100 transition-colors"
            >
              Contact
            </a>
            {/* Dark Mode Toggle */}
            <button
              className="p-2 rounded-full bg-secondary dark:bg-secondary-dark hover:bg-secondary-dark hover:dark:bg-secondary transition-colors"
              onClick={toggleDarkMode}
              aria-label="Toggle dark mode"
            >
              {darkMode ? <Sun className="w-5 h-5" /> : <Moon className="w-5 h-5" />}
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}
