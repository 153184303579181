import React from 'react';

export function ProjectCard({ title, description, imageUrl, tags, onClick }) {
  return (
    <div
      className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden cursor-pointer transition-colors duration-300 flex flex-col"
      onClick={onClick}
    >
      {/* Image */}
      <img src={imageUrl} alt={title} className="w-full h-48 object-cover" />

      {/* Content */}
      <div className="flex-grow p-6">
        <h3 className="text-xl font-semibold mb-2 text-gray-900 dark:text-white">{title}</h3>
        <p className="text-gray-600 dark:text-gray-300">{description}</p>
      </div>

      {/* Tags */}
      <div className="p-4 border-t border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-wrap gap-2">
          {tags.map((tag, index) => (
            <span
              key={index}
              className="text-sm bg-blue-100 dark:bg-blue-700 text-blue-600 dark:text-blue-300 rounded-full px-3 py-1"
            >
              {tag}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}
