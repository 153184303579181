const projects = [
    {
      id: 'loz',
      title: 'loz',
      description: 'Lorem ipsum odor amet, consectetuer adipiscing elit.',
      imageUrl: 'https://placehold.co/400',
      details: 'Detailed information about the project goes here...',
      tags: ['UX Design', 'Figma'],

    },
    {
      id: 'petpal',
      title: 'Petpal',
      description: 'Lorem ipsum odor amet, consectetuer adipiscing elit.',
      imageUrl: 'https://placehold.co/400',
      details: 'Detailed information about the project goes here...',
      tags: ['UX Design','Figma', 'React.JS'],

    },
    {
        id: '3',
        title: 'Lorem ipsum odor amet, consectetuer adipiscing elit.',
        description: 'Lorem ipsum odor amet, consectetuer adipiscing elit.',
        imageUrl: 'https://placehold.co/400',
        details: 'Detailed information about the project goes here...',
        tags: ['SolidWorks'],

      },
  ];
  
  export default projects;
  