import React from 'react';

export function Hero() {
  return (
    <section className="pt-24 pb-12 text-center">
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-bold mb-4 text-gray-900 dark:text-white">Hello, I'm Rachel</h1>
        <h2 className="text-xl mb-8 text-gray-700 dark:text-gray-300">
          A passionate UI/UX designer & frontend developer
        </h2>
      </div>
    </section>
  );
}
