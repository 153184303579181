import React from 'react';
import { Mail, Phone, MapPin, Linkedin } from 'lucide-react';

export function Contact() {
    return (
        <section
            id="contact"
            className="flex flex-col items-center justify-center px-4 py-12"
        >
            <div className="text-center mb-8">
                <h2 className="text-4xl font-bold text-gray-800 dark:text-white mb-4">Get in Touch</h2>
                <p className="text-xl text-gray-600 dark:text-gray-300">
                    Interested in working together? Let's connect!
                </p>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 max-w-4xl w-full">
                {/* Email Section */}
                <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-md p-6 flex items-center space-x-4">
                    <a
                        href="mailto:rachelzrq.zhu@mail.utoronto.ca"
                        className="bg-blue-100 dark:bg-blue-700 rounded-full p-2 hover:bg-blue-200 dark:hover:bg-blue-600 transition"
                        aria-label="Send email"
                    >
                        <Mail className="h-6 w-6 text-blue-600 dark:text-blue-300" />
                    </a>
                    <div>
                        <p className="text-sm text-gray-500 dark:text-gray-400">Email</p>
                        <p className="text-lg text-gray-800 dark:text-gray-100">rachelzrq.zhu@mail.utoronto.ca</p>
                    </div>
                </div>
                {/* Phone Section */}
                <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-md p-6 flex items-center space-x-4">
                    <div className="bg-green-100 dark:bg-green-700 rounded-full p-2">
                        <Phone className="h-6 w-6 text-green-600 dark:text-green-300" />
                    </div>
                    <div>
                        <p className="text-sm text-gray-500 dark:text-gray-400">Phone</p>
                        <p className="text-lg text-gray-800 dark:text-gray-100">+1 (437) 362-1756</p>
                    </div>
                </div>
                {/* Location Section */}
                <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-md p-6 flex items-center space-x-4">
                    <a
                        href="https://www.google.com/maps?q=Toronto,+ON"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-purple-100 dark:bg-purple-700 rounded-full p-2 hover:bg-purple-200 dark:hover:bg-purple-600 transition"
                        aria-label="Open location in Google Maps"
                    >
                        <MapPin className="h-6 w-6 text-purple-600 dark:text-purple-300" />
                    </a>
                    <div>
                        <p className="text-sm text-gray-500 dark:text-gray-400">Location</p>
                        <p className="text-lg text-gray-800 dark:text-gray-100">Toronto, ON</p>
                    </div>
                </div>
                {/* LinkedIn Section */}
                <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-md p-6 flex items-center space-x-4">
                    <a
                        href="https://www.linkedin.com/in/rachelrzhu/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-blue-100 dark:bg-blue-700 rounded-full p-2 hover:bg-blue-200 dark:hover:bg-blue-600 transition"
                        aria-label="Open LinkedIn profile"
                    >
                        <Linkedin className="h-6 w-6 text-blue-600 dark:text-blue-300" />
                    </a>
                    <div>
                        <p className="text-sm text-gray-500 dark:text-gray-400">LinkedIn</p>
                        <p className="text-lg text-gray-800 dark:text-gray-100">linkedin.com/in/rachelrzhu</p>
                    </div>
                </div>
            </div>
        </section>
    );
}
