import React, { useState } from 'react';
import { ProjectList } from '../components/ProjectList';
import projects from '../data/projects';
import { useNavigate } from 'react-router-dom';

function Projects() {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const navigate = useNavigate();

  const handleProjectClick = (projectId) => {
    navigate(`/projects/${projectId}`);
  };

  const categories = ['All', ...new Set(projects.flatMap((project) => project.tags))];

  const filteredProjects =
    selectedCategory === 'All'
      ? projects
      : projects.filter((project) => project.tags.includes(selectedCategory));

  return (
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold text-gray-800 dark:text-white text-center mb-8">Projects</h1>

        {/* Filter Section */}
        <div className="flex justify-center space-x-4 mb-8">
          {categories.map((category) => (
            <button
              key={category}
              onClick={() => setSelectedCategory(category)}
              className={`px-4 py-2 rounded-full text-sm font-medium ${
                selectedCategory === category
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-200 text-gray-700 dark:bg-gray-700 dark:text-gray-300'
              } hover:bg-blue-700 dark:hover:bg-blue-600 transition-colors`}
            >
              {category}
            </button>
          ))}
        </div>

        {/* Project List */}
        <ProjectList projects={filteredProjects} onProjectClick={handleProjectClick} />
      </div>
  );
}

export default Projects;
