'use client';

import React from 'react';
import { Hero } from '../components/Hero';
import { Skills } from '../components/Skills';
import { Contact } from '../components/Contact';
import WorkExperience from '../components/WorkExperience';

export default function PortfolioPage() {
  
  return (
    <div>
      <Hero />
        <Skills />
        <WorkExperience />
        <Contact />
    </div>
  );
}
