import React from 'react';

const Resume = () => {
  return (
    <h1>
        Welcome to My Blog
    </h1>
  );
};

export default Resume;
