import React from 'react';

export default function WorkExperience() {
    const experiences = [
        {
          role: 'Software Developer',
          company: 'ABC company',
          startDate: 'Jan 2023',
          endDate: 'Dec 2023',
          logo: '/images/company-logos/creative-studio.png',
          responsibilities: [
            'To be complete',
          ],
        },
        {
            role: 'Software Developer',
            company: 'ABC company',
            startDate: 'Jan 2023',
            endDate: 'Dec 2023',
            logo: '/images/company-logos/creative-studio.png',
            responsibilities: [
              'To be complete',
            ],
          },
      ];
  return (
    <section id="work-experience" className="py-12 bg-gray-100 dark:bg-gray-900">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-8 text-gray-900 dark:text-white">
          Work Experience
        </h2>
        <div className="space-y-8">
          {experiences.map((experience, index) => (
            <div
              key={index}
              className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 flex flex-col md:flex-row md:space-x-6"
            >
              <div className="flex-shrink-0">
                <img
                  src={experience.logo}
                  alt={`${experience.company} logo`}
                  className="w-24 h-24 rounded-full object-cover"
                />
              </div>
              <div>
                <h3 className="text-2xl font-semibold text-gray-900 dark:text-white">
                  {experience.role}
                </h3>
                <p className="text-lg text-gray-600 dark:text-gray-300">{experience.company}</p>
                <p className="text-sm text-gray-500 dark:text-gray-400 mb-4">
                  {experience.startDate} – {experience.endDate || 'Present'}
                </p>
                <ul className="list-disc list-inside space-y-2 text-gray-700 dark:text-gray-300">
                  {experience.responsibilities.map((responsibility, idx) => (
                    <li key={idx}>{responsibility}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
