import React from 'react';
import { ProjectCard } from './ProjectCard';

export function ProjectList({ projects, onProjectClick }) {
  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
      {projects.map((project) => (
        <ProjectCard
          key={project.id}
          title={project.title}
          description={project.description}
          imageUrl={project.imageUrl}
          tags={project.tags}
          onClick={() => onProjectClick(project.id)}
        />
      ))}
    </div>
  );
}
